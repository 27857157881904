import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7da17ed5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "survey-disclaimer-state" }
const _hoisted_2 = { class: "h2 centered" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t(`surveyState.title`)), 1),
    _createVNode(_component_Input, {
      id: "state-input",
      label: "State",
      name: "StateInput",
      modelValue: _ctx.stateValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stateValue) = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("button", {
      class: "primary-rewards-btn",
      disabled: _ctx.isButtonDisabled
    }, _toDisplayString(_ctx.$t("continue")), 9, _hoisted_3)
  ]))
}