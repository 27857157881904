
import { Options, Vue } from "vue-class-component";

import Layout from "@/components/Layout.vue";
import Title from "@/components/Title.vue";
import Input from "@/components/Input/index.vue";

@Options({
  components: {
    Layout,
    Title,
    Input,
  },
})
export default class ScreenerState extends Vue {
  stateValue = "";

  get isButtonDisabled(): boolean {
    return this.stateValue.length === 0;
  }

  // onInput({ value }: { value: string }): void {
  //   this.stateValue = value;
  // }
}
